import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import gallery_1 from '../images/PhotoGallery/PhotoSlide-1.jpg'
import gallery_2 from '../images/PhotoGallery/PhotoSlide-2.jpg'
import gallery_3 from '../images/PhotoGallery/PhotoSlide-3.jpg'
import gallery_4 from '../images/PhotoGallery/PhotoSlide-4.jpg'
import gallery_5 from '../images/PhotoGallery/PhotoSlide-5.jpg'
import gallery_6 from '../images/PhotoGallery/PhotoSlide-6.jpg'
import gallery_7 from '../images/PhotoGallery/PhotoSlide-7.jpg'
import gallery_8 from '../images/PhotoGallery/PhotoSlide-8.jpg'
import gallery_9 from '../images/PhotoGallery/PhotoSlide-9.jpg'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './SliderPhoto.css';

// import required modules
import { Pagination } from 'swiper/modules';

function SliderPhoto() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                <SwiperSlide><img src={gallery_1} alt="" /></SwiperSlide>
                <SwiperSlide><img src={gallery_2} alt="" /></SwiperSlide>
                <SwiperSlide><img src={gallery_3} alt="" /></SwiperSlide>
                <SwiperSlide><img src={gallery_4} alt="" /></SwiperSlide>
                <SwiperSlide><img src={gallery_5} alt="" /></SwiperSlide>
                <SwiperSlide><img src={gallery_6} alt="" /></SwiperSlide>
                <SwiperSlide><img src={gallery_7} alt="" /></SwiperSlide>
                <SwiperSlide><img src={gallery_8} alt="" /></SwiperSlide>
                <SwiperSlide><img src={gallery_9} alt="" /></SwiperSlide>
            </Swiper>
        </>
    );
}

export default SliderPhoto