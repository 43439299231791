import React, { useMemo } from 'react';
import { useLang } from '../../redux/selectors';
import localData from "../../redux/localization/locale.json";

import "./css/Mission.css";
import Footer from "../Footer"

function Mission() {
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);

    return (
        <div>
            <div className="top-back">
                <div className="content">
                    <h2>{langData['our-mission']}</h2>
                    <p>{langData['about']} / {langData['our-mission']}</p>
                </div>
            </div>

            <div className='mission-container'>
                <h1 className='mission-title'>{langData['our-mission']}</h1>
                <p className='mission-text'>
                    Мы официально начали проводить политику устойчивого развития туризма, благодаря которой мы сможем создавать лучшие условия труда для наших сотрудников и партнеров, и конечно же лучшие условия отдыха для наших туристов.
                </p> <br />
                <p className='mission-text'>
                    Миссия нашей компания заключается в том, чтобы показать, как стандарты и критерии устойчивого развития повлияют на профессиональную деятельность компании, на качество туров для туристов и конечно же на экономическую обстановку в целом регионе. Сохранять. Приумножать. И показывать это миру.
                </p> <br />
                <p className='mission-text'>
                    Мы за сохранение окружающей среды и культурного наследия. Мы за приумножение во всех сферах. Мы против всяческого проявления дискриминации и использования детского труда.
                </p> <br />
                <p className='mission-text'>
                    Мы хотим показать, насколько прекрасен наш Узбекистан. И насколько он привлекателен для туристов со всего мира.
                </p> <br />
                <h1 className='mission-title'>Политика устойчивого развития компании</h1>
                <p className='mission-text'>
                    Компания «Mazzali travel» стремится развиваться устойчиво. Мы определили для себя важные аспекты успешного туристического бизнеса. Подходить ко всем сферам туризма ответственно. Это значит увеличивать положительное влияние устойчивого развития и минимизировать все негативные моменты.
                </p><br />
                <p className='mission-text'>
                    Сохранить окружающую среду и культурное наследие и свести к минимуму негативное влияние на социальные, экологические и экономические сферы деятельности, в ходе внедрения критериев устойчивого развития компании.
                </p><br />
                <p className='mission-text'>
                    Наша компания разработала политику, в которой описывается, что мы уже сделали, что делаем и что планируем сделать для достижения цели развития устойчивого туризма.
                </p><br />
                <h1 className='mission-text'>В нашем офисе</h1><br />
                <p className='mission-text'>
                    Мы внедряем методы сокращения отходов в наших офисах:
                </p><br />
                <p className='mission-text'>
                    Например, мы успешно внедрили политику “без печати” и значительно уменьшили объем печати рекламных материалов, а к необходимым документам по умолчанию применяется двусторонняя печать или другие режимы экономии бумаги;
                </p><br />
                <p className='mission-text'>
                    У нас нет одноразового пластика в офисе (мы используем диспенсеры для воды, не пользуемся одноразовыми столовыми приборами, посудой и т. д.)
                </p><br />
                <p className='mission-text'>
                    Соблюдаем национальное законодательство, касающееся утилизации отходов, а также отделяем материалы, которые могут быть переработаны, и организуем их сбор и надлежащую утилизацию (макулатура и пищевые отходы).
                </p><br />
                <p className='mission-text'>
                    Сортируем и не выбрасываем батарейки в общий бак с отходами.
                </p><br />
                <p className='mission-text'>
                    Мы оцениваем использование природных ресурсов, особенно энергии, и ищем пути сокращения их потребления:
                </p><br />
                <p className='mission-text'>
                    Заменили все световые приборы на энергосберегающее освещение.
                </p><br />
                <p className='mission-text'>
                    Выключаем свет и оборудование, когда они не используются, устанавливаем оборудование по умолчанию в режим энергосбережения и стараемся минимизировать использование кондиционера.
                </p><br />
                <p className='mission-text'>
                    Отдаем предпочтение оборудованию с низким энергопотреблением при покупке новых товаров. Например, наши ноутбуки имеют сертификацию Energy Star.
                </p><br />
                <p className='mission-text'>
                    Мы заботимся о наших сотрудниках и партнерах:
                </p><br />
                <p className='mission-text'>
                    Все наши сотрудники пользуются свободой трудоустройства и справедливыми условиями труда в соответствии с трудовым законодательством.
                </p><br />
                <p className='mission-text'>
                    Мы выступаем за равные возможности и не принимаем дискриминацию во всех ее проявлениях. Мы против использования детского труда.
                </p><br />
                <p className='mission-text'>
                    Имеем аптечки первой помощи и план действий в случае ЧС в офисе и во время туров. Также мы периодически проводим инструктаж и обучение сотрудников по вопросам их роли, прав и обязанностей в области охраны здоровья и безопасности.
                </p><br />
                <p className='mission-text'>
                    Мы предоставляем полную информацию и обучаем наших сотрудников о всех аспектах устойчивого развития.
                </p><br />
                <p className='mission-text'>
                    Мы делимся передовым опытом и повышаем осведомленность об устойчивом развитии среди наших команд и партнеров.
                </p><br />
                <p className='mission-text'>
                    Мы устанавливаем честные и этичные отношения со всеми поставщиками, обеспечивая надлежащую оплату и условия труда.
                </p><br />
                <p className='mission-text'>
                    Обеспечиваем, чтобы наши гиды, турлидеры и другие сотрудники, работающие по контракту, имели необходимую квалификацию и регулярно проходили обучение, включая вопросы устойчивого развития.
                </p><br />
                <p className='mission-text'>
                    Заботимся о наших клиентах:
                </p><br />
                <p className='mission-text'>
                    Информируем наших туристов об экологичных альтернативах в отношении размещения, экскурсий и вариантов транспортировки, если таковые имеются.
                </p><br />
                <p className='mission-text'>
                    Наш маркетинговый процесс для клиентов понятен и прямолинеен. Мы предоставляем исчерпывающую информацию о продукте, включая заявление об устойчивом развитии, и предоставляем услуги строго в соответствии с контрактом и программой.
                </p><br />
                <p className='mission-text'>
                    <strong>Во время наших туров:</strong>
                </p><br />
                <p className='mission-text'>
                    Мы продвигаем и уважаем культурное наследие Узбекистана, взаимодействуя с местными сообществами и подчеркивая традиционные обычаи. Включаем в туры включают посещение местных сообществ, ремесленных мастерских и мастер-классов с участием жителей.
                </p><br />
                <p className='mission-text'>
                    Мы отдаем предпочтение тем средствам размещения, которые отражают местную культуру и защищают наше культурное наследие.
                </p><br />
                <p className='mission-text'>
                    Мы уделяем приоритетное внимание найму местных гидов, водителей и поставщиков услуг.
                </p><br />
                <p className='mission-text'>
                    Мы не проводим визиты в школы или детские сады, поскольку это может нанести вред местному сообществу.
                </p><br />
                <p className='mission-text'>
                    Мы предоставляем нашим гостям информацию о том, как они могут снизить воздействие на окружающую среду во время своих путешествий.
                </p><br />
                <p className='mission-text'>
                    Мы не приглашаем партнеров и клиентов участвовать в деятельности, которая наносит вред человечеству, фауне и флоре, способствует загрязнению воздуха и рек или является социально неприемлемой.
                </p><br />
                <p className='mission-text'>
                    Мы не разрешаем приобретать сувениры, содержащие виды флоры и фауны, находящиеся под угрозой исчезновения, любые незаконно приобретенные исторические/археологические артефакты, наркотики или запрещенные вещества, и соблюдаем местные и международные законы, чтобы предотвратить это.
                </p><br />
                <p className='mission-text'>
                    Экскурсии и туры с участием диких животных в неволе не проводятся, за исключением мероприятий, которые должным образом регулируются и соответствуют местному, национальному и международному законодательству.
                </p><br />
                <p className='mission-text'>
                    Мы не добываем, не потребляем, не демонстрируем и не продаем виды дикой природы, за исключением случаев, когда это является частью регулируемой деятельности, обеспечивающей их устойчивое использование в соответствии с местным, национальным и международным законодательством.
                </p><br />
                <h1 className='mission-title'>Отчет об устойчивом туризме за 2024 год</h1>
                <p className='mission-text'>
                    <strong>В нашей компании:</strong>
                </p><br />
                <p className='mission-text'>
                    - Руководитель выбран координатором по устойчивому туризму, который прошел обучение в Travelife.
                </p><br />
                <p className='mission-text'>
                    - Разработали Политику Устойчивого развития компании. И прикрепляем ее к пакету документов с новыми партнерами.
                </p><br />
                <p className='mission-text'>
                    - Заменили лампу на энергосберегающую.
                </p><br />
                <p className='mission-text'>
                    - Организовали контейнер для сбора бумаги.
                </p><br />
                <p className='mission-text'>
                    - Поставили небольшой контейнер для сбора использованных батареек.
                </p><br />
                <p className='mission-text'>
                    - Возле офиса установили мусорный бак для раздельного мусора.
                </p><br />
                <p className='mission-text'>
                    - Полностью отказались от использования одноразовых стаканов в офисе в пользу стеклянных.
                </p><br />
                <p className='mission-text'>
                    - Отказались от использования воды в пластиковых бутылка, приобрели кулер.
                </p><br />
                <p className='mission-text'>
                    - На всех выключателя и розетках прикрепили напоминания о сохранении энергоресурсов.
                </p><br />
                <p className='mission-text'>
                    - Свели до минимума печать документов. Перешли на электронный документооборот.
                </p><br />
                <p className='mission-text'>
                    - Разработали и внедрили в работу ряд инструкций и памяток как для гидов, так и для туристов.
                </p><br />
                <p className='mission-text'>
                    - На сайте разместили информацию о том ка можно добраться в наш регион. Авиа и ж.д. сообщения.
                </p><br />
                <p className='mission-text'>
                    - Туристам стали давать более полную информацию регионе со стороны устойчивости. Привлекаем к местным ремесленникам.
                </p><br />
                <p className='mission-text'>
                    - Разработали план действий на 2025 год для дальнейшего устойчивого развития.
                </p><br />
                <p className='mission-text'>
                    Компания «Mazzali travel» будет и дальше продолжать работать для устойчивого развития. А также информировать и привлекать своих партнеров, коллег и туристов.
                </p><br />
            </div>
            <Footer />
        </div>
    )
}

export default Mission;