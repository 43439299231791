import React, { useMemo } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json"

const Footer = () => {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);

  return (
    <div>
      <footer className="footer">
        <div className="footer-row">
          <div className="footer-column">
            <h3>{langData['contact-info']}</h3>
            <p><FontAwesomeIcon icon={faLocationDot} /> Узбекистан Город Наманган 5 микр. 25а</p>
            <p><FontAwesomeIcon icon={faPhone} /> Phone: +998(97)2525147</p>
            <p><FontAwesomeIcon icon={faEnvelope} /> Email:  mazzalitravel@gmail.com</p>
          </div>
          <div className="footer-column">
            <h3>{langData['last-post']}</h3>
            <ul>
              <li>Тур в Чадак</li> <br /> <hr /> <br />
              <li>Однодневный тур в Учкурган.</li> <br /> <hr /> <br />
              <li>На фотографиях - узбекский виноградарь. Он был ученым с мировым именем.</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>{langData['follow-us']}</h3>
            <input type="email" placeholder="Enter your email" />
            <button>{langData['follow-now']}</button>
          </div>
        </div>
      </footer>
      <div className='footer-copyright'>
      <h2 style={{ margin: 0, display: 'flex', alignItems: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>
        <FontAwesomeIcon icon={faCopyright} style={{ marginRight: '8px', color: '#fff'}} />
        Mazzali Travel 2024, All rights reserved
      </h2>
    </div>
    </div>
  );
};

export default Footer;