import React, { useMemo } from 'react';
import { useLang } from '../../redux/selectors';
import localData from "../../redux/localization/locale.json";

import "./css/Mission.css";
import Footer from "../Footer"

function Mission() {
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);

    return (
        <div>
            <div className="top-back">
                <div className="content">
                    <h2>{langData['for-tourist']}</h2>
                    <p>{langData['about']} / {langData['for-tourist']}</p>
                </div>
            </div>

            <div className='mission-container'>
                <h1 className='mission-title'>Дорогой гость, добро пожаловать!</h1>
                <p className='mission-text'>
                    Доброго времени суток, дорогой наш путешественник. Мы очень рады, что Вы выбрали именно нашу компанию для своего путешествия.
                </p> <br />
                <p className='mission-text'>
                    Перед тем как отправиться в увлекательное путешествие, мы хотели бы дать несколько рекомендаций для того, чтобы Ваш тур прошел более комфортно и интересно.
                </p> <br />
                <p className='mission-text'>
                    Конечно же самое основное, что Вы должны взять с собой в тур, это – хорошее настроение!
                </p> <br />
                <p className='orange-text'>
                    Общая информация
                </p> <br />
                <p className='mission-text'>
                    <strong>Страна:</strong> Республика Узбекистан
                </p> <br />
                <p className='mission-text'>
                    <strong>Столица:</strong> Ташкент
                </p> <br />
                <p className='mission-text'>
                    <strong>Денежная единица: </strong> UZS узбекский сум
                </p> <br />
                <p className='mission-text'>
                    <strong>Государственный язык: </strong> узбекский. Русский язык используется в качестве языка межнационального общения
                </p> <br />
                <p className='orange-text'>
                    Климат
                </p> <br />
                <p className='mission-text'>
                    Климат в Узбекистане резко континентальный, отличается от сухого и засушливого.
                </p> <br />
                <p className='mission-text'>
                    Температура в Узбекистане колеблется в течение года, от +40˚C летом (июль и август) и -20˚C в середине зимы (январь и февраль). Влажность довольно низкая. Весной и осенью падение температуры имеет более умеренную природу (в среднем от 20˚C  до 30˚C).
                </p> <br />
                <p className='mission-text'>
                    Разница в температуре в зависимости от времени года, времени суток и рельефа местности (горы, равнины, пустыня) довольно значительна.
                </p> <br />
                <p className='orange-text'>
                    Часовые пояса / разница во времени
                </p> <br />
                <p className='mission-text'>
                    Стандартный часовой пояс: UTC/ GMT +5 часов. Переход на летнее/ зимнее время не осуществляется.
                </p> <br />
                <p className='orange-text'>
                    Население
                </p> <br />
                <p className='mission-text'>
                    Население Узбекистана - около 30 млн чел. Этнически народы на территории Узбекистана - узбеки (80 %), русские (5 %), таджики (5, 5 %), казахи (0, 8 %), каракалпаки (3 %), татары (1 %) и другие.
                </p> <br />
                <p className='orange-text'>
                    Религия
                </p> <br />
                <p className='mission-text'>
                    Основная религия — ислам, также распространено христианство.
                </p> <br />
                <p className='orange-text'>
                    Таможенный режим
                </p> <br />
                <p className='mission-text'>
                    Ввозить можно любую сумму, ограничений нет. Если сумма ввозимых средств менее 6000 USD или эквивалент в другой валюте, и нет специально декларируемых товаров, то можно воспользоваться «зеленым коридором», таможенная декларация не нужна.
                </p> <br />
                <p className='mission-text'>
                    Если ввозимая сумма превышает 6000 USD, то необходимо заполнить таможенную декларацию и сохранить ее до окончания путешествия.
                </p> <br />
                <p className='mission-text'>
                    Запрещается ввоз в Республику Узбекистана печатных изданий, рукописей, клише, рисунков, фотоснимков, фотопленки, негативов, кино-, видео- и аудио продукции, грамзаписей, звуковых материалов, направленных на подрыв государственного и общественного строя, нарушение территориальной целостности, политической независимости и государственного суверенитета, пропагандирующих войну, терроризм, насилие, национальную исключительность и религиозную ненависть, расизм и его разновидности (антисемитизм, фашизм), а также материалы порнографического содержания.
                </p> <br />
                <p className='mission-text'>
                    В Узбекистане действует запрет на ввоз и использование дронов.
                </p> <br />
                <p className='mission-text'>
                    Дети до 16 лет освобождаются от заполнения въездной таможенной декларации в аэропорту.
                </p> <br />
                <p className='mission-text'>
                    В Узбекистан разрешен беспошлинный ввоз алкогольной продукции в объеме 1,5-2 литра и табачных изделий в количестве 10 пачек.
                </p> <br />
                <p className='mission-text'>
                    С собой разрешено ввозить / вывозить до 10 различных лекарственных препаратов и не более 5 упаковок каждого из них на человека.
                </p> <br />
                <p className='mission-text'>
                    Со списком запрещенных к ввозу лекарств в Узбекистан вы можете ознакомиться на сайте МИДа Узбекистана.
                </p> <br />
                <p className='orange-text'>
                    Деньги
                </p> <br />
                <p className='mission-text'>
                    Все платежи на территории Узбекистана производятся в национальной валюте – узбекских сумах (UZS).
                </p> <br />
                <p className='mission-text'>
                    В обменных пунктах меняют доллары/евро/рубли; рекомендуем ввозить только новые и немятые купюры без заломов, надписей и каких-либо других отметок. Обменные пункты имеются в банках, аэропортах, ж/д вокзалах (работают с 09:00 до 22:00 по будням и с 09:00 до 16:00 по выходным), крупных гостиницах (круглосуточно).
                </p> <br />
                <p className='mission-text'>
                    В больших городах имеются круглосуточные банкоматы, где можно обменять наличную валюту (доллары США и евро).
                </p> <br />
                <p className='orange-text'>
                    Связь
                </p> <br />
                <p className='mission-text'>
                    Международный телефонный код Узбекистана: + 998.
                </p> <br />
                <p className='mission-text'>
                    Туристическую SIM-карту узбекских сотовых операторов можно приобрести в международных аэропортах Ташкента и Бухары. Для этого необходимо обратиться к сотрудникам информационной туристической стойки, предъявить паспорт, выбрать тарифный план и внести за него оплату.
                </p> <br />
                <p className='orange-text'>
                    Электричество
                </p> <br />
                <p className='mission-text'>
                    Переменный ток 220 вт, 50 Am. Двойные континентальные штепсельные вилки стандартные.
                </p> <br />
                <p className='orange-text'>
                    Личная безопасность
                </p> <br />
                <p className='mission-text'>
                    Узбекистан – безопасная для путешествий страна, где практически отсутствует уличная преступность. Отношение к иностранцам и к представителям различных религиозных конфессий толерантное, гостеприимное и благожелательное.
                </p> <br />
                <p className='mission-text'>
                    ⦁	Следует носить с собой паспорт (или копию паспорта).
                </p> <br />
                <p className='mission-text'>
                    ⦁	Не рекомендуется менять валюту у частных лиц, на базарах, на улице.
                </p> <br />
                <p className='mission-text'>
                    ⦁	Не рекомендуется вести фото- и видеосъемку в аэропорту, ж/д вокзалах, религиозных местах. За фото- и видеосъёмку на территории музеев и исторических памятников может взиматься дополнительная плата.
                </p> <br />
                <p className='mission-text'>
                    ⦁	Значительно число туристов, посещающих Узбекистан, отмечают, что уровень вождения в стране находится на довольно низком уровнем. Часто нарушаются правила дорожного движения, пешеходы переходят дорогу неположенных местах, а водители, в свою очередь, могут игнорировать пешеходов и велосипедистов. Важно помнить, что правила не заменяют здравого смысла. Переходя дорогу всегда смотрите по сторонам, а находясь за рулем автомобиля будьте как можно более осмотрительны.
                </p> <br />
                <p className='orange-text'>
                    Внешний вид и правила поведения
                </p> <br />
                <p className='mission-text'>
                    Ограничений и предписаний при выборе одежды нет ни для мужчин, ни для женщин – надевайте любую комфортную и привычную для себя одежду, в т.ч. шорты, майки и т.д. В жаркую погоду лучше отдавать предпочтение дышащей одежде из натуральных тканей и носить головной убор.
                </p> <br />
                <p className='mission-text'>
                    Однако среди достопримечательностей Средней Азии часто встречаются мусульманские святыни, использующиеся для богослужений или как места поклонения. Чтобы не оскорблять чувств верующих и вести себя корректно в святых местах, следует придерживаться простых правил, схожих с аналогичными правилами поведения в других религиях:
                </p> <br />
                <p className='mission-text'>
                    ⦁	Категорически запрещено сквернословить и использовать неприличные жесты, беспокоить мусульманина во время молитвы и нарушать его личное пространство;
                </p> <br />
                <p className='mission-text'>
                    ⦁	Следует вести себя сдержанно, не мешать другим, избегать громких возгласов и других бурных проявлений эмоций, даже в форме жестикуляции;
                </p> <br />
                <p className='mission-text'>
                    ⦁	От жевания жвачки и употребления любой еды и напитков, а также от разговоров по мобильному телефону нужно воздержаться. Следует также отключить звук мелодии звонка телефона;
                </p> <br />
                <p className='mission-text'>
                    ⦁	Одежда: не вызывающая, не просвечивающая и не облегающая, спокойной расцветки, желательно однотонная;
                </p> <br />
                <p className='mission-text'>
                    ⦁	Шею, руки и ноги следует обязательно прикрыть, особенно женщинам;
                </p> <br />
                <p className='mission-text'>
                    ⦁	Женщинам надлежит покрывать голову или хотя бы спрятать волосы под символическим шарфиком; макияж спокойный, не яркий, а лучше и вовсе без него;
                </p> <br />
                <p className='mission-text'>
                    ⦁	В некоторых местах требуется снимать обувь. Об этом можно легко узнать по наличию обуви перед входом или от гида. Не стоит бояться кражи своих ботинок - это полностью исключено. Если вы вышли на прогулку в босоножках, то желательно захватить с собой пару чистых носков, чтобы потом не бояться ходить по общественным коврам босиком.
                </p> <br />
                <p className='orange-text'>
                    Чаевые
                </p> <br />
                <p className='mission-text'>
                    Чаевые в Узбекистане не обязательны, но приветствуются. Для водителей и гидов, а также обслуживающего персонала гостиниц и ресторанов подобный жест благодарности со стороны гостя вполне уместен.
                </p> <br />
                <p className='mission-text'>
                    В среднем "на чай" здесь принято оставлять:
                </p> <br />
                <p className='mission-text'>
                    ⦁	Гиду: от 10 USD с человека за программу тура.
                </p> <br />
                <p className='mission-text'>
                    ⦁	Водителю: от 5 USD с человека за программу тура.
                </p> <br />
                <p className='mission-text'>
                    ⦁	Служба подноса багажа в гостинице: от 1 USD с человека.
                </p> <br />
                <p className='mission-text'>
                    ⦁	Официанту: в пределах 5-10% от стоимости счета в ресторане.
                </p> <br />
                <p className='orange-text'>
                    Вывоз культурных ценностей и незаконных сувениров
                </p> <br />
                <p className='mission-text'>
                    Некоторые художественные изделия (ковры, сюзане, антиквариат, музыкальные инструменты, художественная мебель, художественная литература старше 50 лет, живопись, графика, скульптура, художественные изделия из серебра, серебряная посуда) подлежат экспертизе, без которой вывоз этих предметов из Узбекистана невозможен.
                </p> <br />
                <p className='mission-text'>
                    Если у вас есть вопросы по поводу экспертизы, пожалуйста, обращайтесь к продавцам, гидам, водителям или нашим сотрудникам.
                </p> <br />
                <p className='orange-text'>
                    Транспорт
                </p> <br />
                <p className='mission-text'>
                    Общественный транспорт широко распространен во всех городах Узбекистана. В Узбекистане представлены все виды общественного транспорта, такие как: автобус, троллейбус, трамвай, метрополитен и такси.
                </p> <br />
                <p className='mission-text'>
                    Общественный городской транспорт ходит с 06:00 до 23:00 часов с перерывами в 10-15 минут
                </p> <br />
                <p className='mission-text'>
                    Ташкентское метро признано одним из самых красивых в мире. Каждая станция – отдельное произведение искусства с уникальной концепцией. Величественные колонны, искусные барельефы, колоритные фрески, пышные люстры, мрамор, гранит, керамика, ганч – метро в Ташкенте не просто городской транспорт, а настоящая художественная достопримечательность.
                </p> <br />
                <p className='mission-text'>
                    Стоимость проезда в автобусах Ташкента идентична стоимости проезда в метрополитене и составляет 1700 сум, при оплате наличными — 2000 сум.
                </p> <br />
                <p className='mission-text'>
                    В целом передвигаться по городу комфортнее и быстрее всего на такси. В Ташкенте, Самарканде, Бухаре, Фергане, Маргилане, Намангане и Андижане работает сервис Яндекс.Такси, машину можно заказать через мобильное приложение.
                </p> <br />
                <p className='mission-text'>
                    Для перемещения между городами существует развитая железнодорожная сеть, включая высокоскоростную железную дорогу, соединяющую крупнейшие города Узбекистана Ташкент, Самарканд и Бухару.
                </p> <br />
                <p className='orange-text'>
                    Наши принципы устойчивого туризма
                </p> <br />
                <p className='mission-text'>
                    <strong>⦁	Экологическая ответственность</strong>
                </p> <br />
                <p className='mission-text'>
                    <strong>⦁	Экономический рост и социальная ответственность</strong>
                </p> <br />
                <p className='mission-text'>
                    <strong>⦁	Сохранение культурного наследия</strong>
                </p> <br />
                <p className='mission-text'>
                    ⦁	Пожалуйста, используйте многоразовые бутылки для воды.
                </p> <br />
                <p className='mission-text'>
                    ⦁	Старайтесь минимизировать мусор, сортируйте и правильно утилизируйте отходы.
                </p> <br />
                <p className='mission-text'>
                    ⦁	Пожалуйста, соблюдайте правила при посещении исторических, культурных и природных достопримечательностей и не причиняйте им вред.
                </p> <br />
                <p className='mission-text'>
                    ⦁	На протяжении маршрута, мы порекомендуем Вам мастерские местных ремесленников, где Вы сможете приобрести сувениры местного производства.  А также мы подскажем Вам какие сувениры и товары запрещены для покупок. Инвестируя в местную экономику, вы поддерживаете предпринимательство и помогаете создавать рабочие места в обществе.
                </p> <br />
                <p className='mission-text'>
                    ⦁	Хотя сексуальная и коммерческая эксплуатация не является серьезной проблемой в Узбекистане, мы стремимся предотвратить любые подобные инциденты. Мы призываем вас сохранять бдительность и сообщать о любом подозрительном поведении.
                </p> <br />
                <p className='orange-text'>
                    Экстренные контакты
                </p> <br />
                <p className='mission-text'>
                    <strong>Единый номер вызова экстренных служб: </strong>112
                </p> <br />
                <p className='mission-text'>
                    <strong>Полиция: </strong>102
                </p> <br />
                <p className='mission-text'>
                    <strong>Скорая помощь: </strong>103
                </p> <br />
                <p className='mission-text'>
                    <strong>Пожарная служба: </strong>101
                </p> <br />
                <p className='mission-text'>
                    <strong>Телефоны сотрудников компании Mazzali Travel для экстренной связи:</strong>Гид/сопровождающий группы
                </p> <br />
                <p className='mission-text'>
                    <strong>Тел.: </strong>Менеджер отвечающий за туристическую программу
                </p> <br />
                <p className='mission-text'>
                    <strong>Тел.: +998(94)794 00 49</strong>Руководитель компании «Mazzali Travel»
                </p> <br />
                <p className='mission-text'>
                    <strong>Тел.: +998(97)252 51 47</strong>
                </p> <br />
                <p className='mission-text'>
                    Надеемся, ваше пребывание будет незабываемым и полным приятных впечатлений.
                </p> <br />
                <p className='mission-text'>
                    Добро пожаловать!
                </p> <br />
            </div>
            <Footer />
        </div>
    )
}

export default Mission;