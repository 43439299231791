import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ru from '../images/ru.ico'
import uz from '../images/uz.svg';
import de from '../images/de.svg';
import us from '../images/us.svg'
import { useLang } from "../redux/selectors"
import { setLang } from "../redux/lang"

const langData = [
    {
        id: 1,
        lang: 'ru',
        img: ru,
    },
    {
        id: 2,
        lang: 'de',
        img: de,
    },
    {
        id: 3,
        lang: 'en',
        img: us,
    },
    {
        id: 4,
        lang: 'uz',
        img: uz,
    }
]

const Header = () => {
    const languages = useLang();
    const dispatch = useDispatch();

    return (
        <div className="header">
            <div className="languages">
                {langData.filter(({ lang }) => lang !== languages).map(({ id, img, lang }) => {
                    return <span onClick={() => dispatch(setLang(lang))} key={id}><img src={img} alt="" /></span>
                })}
                <span className='icon-header'><FontAwesomeIcon icon={faLocationDot} /><a href="https://maps.windows.com/?form=WNAMSH&collection=point.40.996026_71.601546_Point"> Узбекистан Город Наманган 5 микр. 25а</a></span>
                <span className='icon-header'><FontAwesomeIcon icon={faPhone} /><a href="https://mail.google.com/mail/u/0/#inbox?compose=NZVHFwVwRFzzFDdmbTfsXhSkscZCpRhPnbvGnXGkvdlDZXJgXSPnvzTGKnvsKPkCspTnQB"> mazzalitravel@gmail.com</a></span>
                <span className='icon-header'><FontAwesomeIcon icon={faEnvelope} /> <a href="972525147">+998(97)2525147</a></span>
            </div>
            <div className="social-icons">
                <a href="https://www.instagram.com/mazzali_travel/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://t.me/mazzalitravel" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTelegram} />
                </a>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} />
                </a>
            </div>
        </div>
    );
};

export default Header;
