import React, { useEffect, useState } from 'react';
import './Collaj.css';
import { BASE_IMG, getAllToursGal } from '../api'; // Ensure the correct path to your api

const Collaj = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        getAllToursGal()
            .then(({ data }) => {
                setImages(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <div className="collage-container">
            {images.map((image, index) => (
                <img key={index} src={`${BASE_IMG}${image.img}`} alt={`Collage ${index}`} className="collage-image" />
            ))}
        </div>
    );
};

export default Collaj;
