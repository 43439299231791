import React, { useMemo } from 'react';
import '../SliderPart.css'; // Assuming your CSS file is named App.css
import Footer from '../Footer';
import SliderPhoto from '../SliderPhoto';
import Collaj from '../Collaj';
import { useLang } from '../../redux/selectors';
import localData from "../../redux/localization/locale.json"


const Gallery = () => {
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);

    return (
        <main>
            <div className="top-back">
                <div className="content">
                    <h2>{langData['photo-gallery']} </h2>
                    <p>{langData['home']} / {langData['photo-gallery']}</p>
                </div>
            </div>
            <div className="page">
                <header>
                    <h2 className='home-h2'>- {langData['photo-gallery']} -</h2>
                    <h1 className='home-h1'>{langData['photos-tourists']}</h1>
                </header>
                <div className="centered-div">
                    <SliderPhoto/>
                </div>
            </div>
            <Collaj />
            <Footer />
        </main>
    );
};

export default Gallery;