import React, { useMemo } from 'react';
import { useLang } from '../../redux/selectors';
import localData from "../../redux/localization/locale.json";
import Uzb from "../../images/uzbekistan.jpeg";
import Nam from "../../images/namangan.jpg";
import And from "../../images/andijan.webp";
import Fer from "../../images/fergana.jpg";

import Footer from "../Footer"
import "./css/AboutUzbekistan.css";

function AboutUzbekistan() {
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);

    return (
        <div>
            <div className="top-back">
                <div className="content">
                    <h2>{langData['about-uzb']}</h2>
                    <p>{langData['about']} / {langData['about-uzb']}</p>
                </div>
            </div>
            <div className='uzb-container'>
                <h1 className='mission-title'>Узбекистан</h1>
                <div className='uzb-flex'>
                    <div className='uzb-text'>
                        <p className='uzb-textt'>
                            Путешествие в Узбекистан – это исследование древних городов с голубыми куполами и минаретами, поездки через бескрайние пустыни к тысячелетним крепостям и храмам, а также походы к удивительным чудесам природы у подножия Гиссарских и Чаткальских гор. А еще, эта восточная страна притягивает путешественников своим гостеприимством, древними традициями и вкуснейшей кухней.
                            <br /><br />
                            На протяжении тысячелетий этот край был местом пересечения культур и богатой истории. Здесь зарождался Великий Шелковый путь, развивались зороастризм, буддизм и ислам. Оазисы городов посреди огромной пустыни были культурными и торговыми центрами. Здесь сохранилось множество мечетей и дворцов, поражающих не только своими масштабами, пропорциями, но и вкусом и мастерством строивших их зодчих.
                        </p>
                    </div>
                    <div className='uzb-img'>
                        <img src={Uzb} alt='' />
                    </div>
                </div>
                <h1 className='mission-title'>Наманган, Узбекистан</h1>
                <div className='uzb-flex'>
                    <div className='uzb-img-left'>
                        <img src={Nam} alt='' />
                    </div>
                    <div className='uzb-text-left'>
                        <p className='uzb-textt'>
                            Чем заняться в Намангане – достопримечательности
                            <br /><br />
                            Город Наманган ведет свою историю с XVII века, когда здесь, на севере Ферганской долины, возникает поселение для добытчиков местных солевых руд (наман канн). В XVII веке сюда, после разрушительного землетрясения переселились жители Ахсикента (Ахсыкента) – древнего города, бывшего когда-то центром Ферганской долины. В середине XVIII века город стал административным центром области.
                            <br /><br />
                            В 1875 году был заложен по регулярному плану новый город. Его отделяла от старого города крепость, от которой, по уже сложившемуся порядку, веером расходились радиальные улицы. В начале ХХ века Наманган был вторым по населению городом и центром по обработке хлопка в Ферганской долине, а затем и вторым по численности населения в республике, после Ташкента.
                            <br /><br />
                            В настоящее время Наманган развивается как центр легкой и пищевой промышленности. Город утопает в зелени фруктовых садов и парков.
                            <br /><br />
                            Недалеко от Намангана находятся развалины древнего города Ахсикента. Археологические исследования показали, что город имел цитадель и мощные крепостные стены. Здесь была развита торговля и ремесленное производство. До ХIII века Ахсикент был столицей Ферганской долины. Но затем город был разрушен монголами, а в ХVII веке сильнейшим землетрясением.
                        </p>
                    </div>

                </div>
                <h1 className='mission-title'>Андижан, Узбекистан</h1>
                <div className='uzb-flex'>
                    <div className='uzb-text'>
                        <p className='uzb-textt'>
                            Чем заняться в Андижане - достопримечательности
                            <br /><br />
                            Современный Андижан - один из крупных городов Узбекистана, центр машиностроения. Здесь находится завод по выпуску легковых автомобилей, имеется ряд других крупных предприятий: машиностроительный, консервный и молочный заводы, мелькомбинат, хлопчатобумажный комбинат, трикотажная фабрика.
                            <br /><br />
                            В 30 километрах от Андижана расположено древнее городище Эрши - столица древнего государства Давань. Также Андижанская область известна своими святыми местами. Самое известное из них Тешик-Таш, представляющий собой валун, основание которого похоже на широко расставленные ноги гиганта. Среди других известных святынь - Имам-Ота, Тузлик Масар, Ок Гур, родник Ширманбулак.
                        </p>
                    </div>
                    <div className='uzb-img'>
                        <img src={And} alt='' />
                    </div>
                </div>
                <h1 className='mission-title'>Фергана, Узбекистан</h1>
                <div className='uzb-flex'>
                    <div className='uzb-img-left'>
                        <img src={Fer} alt='' />
                    </div>
                    <div className='uzb-text-left'>
                        <p className='uzb-textt'>
                            Чем заняться в Фергане – достопримечательности
                            <br /><br />
                            Город Фергана, расположенный на юге Ферганской долины, относится к числу самых юных городов Узбекистана. Он образован в 1876 году, после присоединения Кокандского ханства к Российской империи. Тогда, в 12 километрах от Маргилана, был выстроен город, получивший название Новый Маргилан и ставший центром Ферганской области.
                            <br /><br />
                            Главным сооружением нового города стала военная крепость, занимавшая большую площадь. От нее веером были продолжены новые улицы. В центре города был разбит большой парк. Первыми архитектурными сооружениями города стали Дом губернатора (в настоящее время здание драматического театра), Дом помощника губернатора, Военное собрание (Дом офицеров). В 1907 город был переименован в Скобелев, а с 1924 года называется Ферганой.
                            <br /><br />
                            Пригороды Ферганы тоже славятся чрезвычайно красивыми местами. В предгорьях Алайского хребта, утопая в зелени садов и виноградников, раскинулось селение Чимен, известное своими целебными минеральными источниками, на основе которых открыт бальнеологический санаторий «Чимен» - крупнейшая здравница в Центральной Азии.
                            <br /><br />
                            В 55 километрах от Ферганы расположен живописный уголок Узбекистана – Шахимардан. Это традиционное место отдыха жителей Узбекистана и Кыргызстана.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUzbekistan;