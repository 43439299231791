import React, { useMemo } from 'react';
import { Link } from "react-router-dom";
import './Homepage.css';
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json"

const Homepage = () => {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);

  return (
    <div className="container">
      <div className="content">
        <h1 className="responsive-text">{langData['tour-agent']} <br /> Mazzali Travel</h1>
        <Link to="/contact"><button className="responsive-button">{langData['book-now']}</button></Link>
      </div>
    </div>
  );
};

export default Homepage;
