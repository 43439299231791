import React, { useMemo } from 'react';
import './Contact.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaInstagram, FaTelegram } from 'react-icons/fa';
import Footer from '../Footer';
import { useLang } from '../../redux/selectors';
import localData from "../../redux/localization/locale.json"


const Contact = () => {
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);

    return (
        <main>
            <div className="top-back">
                <div className="content">
                    <h2>{langData['contact-with']}</h2>
                    <p>{langData['home']} / {langData['contact']}</p>
                </div>
            </div>
            <div className="container-contact">
                <div className="contact-info">
                    <div className="info-item">
                        <FaEnvelope />
                        <p>mazzalitravel@gmail.com</p>
                    </div>
                    <div className="info-item">
                        <FaPhone />
                        <p>+998(97)2525147</p>
                    </div>
                    <div className="info-item">
                        <FaMapMarkerAlt />
                        <p>Узбекистан Город Наманган 5 микр. 25а</p>
                    </div>
                    <div className="info-item">
                        <FaInstagram />
                        <p><a href="">Instagram</a></p>
                    </div>
                    <div className="info-item">
                        <FaTelegram />
                        <p><a href="">Telegram</a></p>
                    </div>
                </div>
                <div className="contact-form">
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                    <input type="text" placeholder="Phone Number" />
                    <textarea placeholder="Message"></textarea>
                    <button type="submit">Отправить</button>
                </div>
            </div>
            <Footer />
        </main>
    );
};

export default Contact;
