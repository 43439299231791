import React, { useMemo } from 'react';
import './Testimonial.css';
import { FaStar } from "react-icons/fa";

import test_1 from '../images/Testimonials/Testimonial-1.jpg'
import test_2 from '../images/Testimonials/Testimonial-2.jpg'
import test_3 from '../images/Testimonials/Testimonial-3.jpg'
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json"

const newsItems = [
    {
        id: 2,
        imgSrc: test_1,
        altText: "Placeholder",
        description: "Валентина Умарова",
        date: "Асель,спасибо большое за чудесное воскресенье! Мы провели прекрасный день в хорошей компании. Как всегда всё было супер 👍! Центр Ремеслинников-это -восхищение кропотливым ручным трудом 👍👍👍 Замечательный спектакль . Браво всему коллективу Ферганского Русского Драматического Театра👏 Ну,и конечно удалась дегустация😄. Огромное всем спасибо !!!",
    },
    {
        id: 1,
        imgSrc: test_2,
        altText: "Placeholder",
        description: "Мавлюда Расулова",
        date: "Асель,огромное спасибо за доставленное нам всем  удовольствие! Работа ремесленников,винные погреба,вкусный обед,отличная компания,теплая дружеская обстановка-все это  огромное чувство положительных эмоций! А прекрасно поставленный спектакль Барышня-крестьянка вобще покорил не только нас ,но и наше молодое поколение,наших внучек! ",
    },
    {
        id: 3,
        imgSrc: test_3,
        altText: "Placeholder",
        description: "Зинченко Анастасия",
        date: "Хочу поблагодарить это агенство. Вы лучшие в своем деле благодаря Вам, в моей жизни все пошло в лучшую сторону. Особенно персоналу спасибо, желаю дальнейших успехов Вам. Вы лучшие.  ",
    },
];

const Testimonial = () => {
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);

    return (
        <div className="container-test">
            <header>
                <h2 className='news-h2'>- {langData['reviews']} -</h2>
                <h1 className='news-h1'>{langData['ОТЗЫВЫ ОТ ПУТЕШЕСТВЕННИКОВ']}</h1>
            </header>
            <div className="test-cards">
                {newsItems.map(item => (
                    <div className="test-card" key={item.id}>
                        <img src={item.imgSrc} alt={item.altText} />
                        <strong>{item.description}</strong>
                        <p>{item.date}</p>
                        <div className='star'>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Testimonial;