import React, { useMemo } from 'react';
import { useLang } from '../../redux/selectors';
import localData from "../../redux/localization/locale.json";
import { BiSolidArrowFromBottom, BiSolidArrowFromTop } from "react-icons/bi";
import { BsCalendar2DayFill } from "react-icons/bs";

import Footer from "../Footer"
import "./css/Tickets.css";

function Tickets() {
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);

    const ticketData = [
        {
            from: "Наманган",
            to: "Ташкент",
            dates: ["Ежедевно"]
        },
        {
            from: "Ташкент",
            to: "Наманган",
            dates: ["Понедельник", "Вторник", "Четверг", "Пятница"]
        },
        {
            from: "Наманган",
            to: "Самарканд",
            dates: ["Понедельник", "Четверг", "Пятница"]
        },
        {
            from: "Самарканд",
            to: "Наманган",
            dates: ["Среда", "Суббота", "Воскресенье"]
        },
        {
            from: "Наманган",
            to: "Бухара",
            dates: ["Понедельник", "Четверг", "Пятница"]
        },
        {
            from: "Бухара",
            to: "Наманган",
            dates: ["Среда", "Суббота", "Воскресенье"]
        },
        {
            from: "Наманган",
            to: "Нукус",
            dates: ["Понедельник", "Четверг", "Пятница"]
        },
        {
            from: "Нукус",
            to: "Наманган",
            dates: ["Среда", "Суббота", "Воскресенье"]
        },
        {
            from: "Наманган",
            to: "Навои",
            dates: ["Понедельник", "Четверг", "Пятница"]
        },
        {
            from: "Навои",
            to: "Наманган",
            dates: ["Среда", "Суббота", "Воскресенье"]
        },
        {
            from: "Наманган",
            to: "Джизак",
            dates: ["Понедельник", "Четверг", "Пятница"]
        },
        {
            from: "Джизак",
            to: "Наманган",
            dates: ["Среда", "Суббота", "Воскресенье"]
        }
    ];

    const ticketPlane= [
        {
            from: "Наманган",
            to: "Ташкент",
            dates: ["Ежедевно"]
        },
        {
            from: "Ташкент",
            to: "Наманган",
            dates: ["Понедельник", "Вторник", "Четверг", "Пятница"]
        },
        {
            from: "Наманган",
            to: "Москва",
            dates: ["Ежедевно"]
        },
        {
            from: "Москва",
            to: "Наманган",
            dates: ["Ежедевно"]
        },
        {
            from: "Наманган",
            to: "Уфа",
            dates: ["Среда"]
        },
        {
            from: "Уфа",
            to: "Наманган",
            dates: ["Четверг"]
        },
        {
            from: "Наманган",
            to: "Самара",
            dates: ["Четверг", "Воскресенье"]
        },
        {
            from: "Самара",
            to: "Наманган",
            dates: ["Понедельник", "Пятница"]
        },
        {
            from: "Наманган",
            to: "Сочи",
            dates: ["Вторник", "Четверг"]
        },
        {
            from: "Сочи",
            to: "Наманган",
            dates: ["Вторник", "Четверг"]
        },
        {
            from: "Наманган",
            to: "Иркутск",
            dates: ["Среда", "Суббота"]
        },
        {
            from: "Иркутск",
            to: "Наманган",
            dates: ["Среда", "Суббота"]
        }
    ];
    return (
        <div>
            <div className="top-back">
                <div className="content">
                    <h2>{langData['tickets']}</h2>
                    <p>{langData['about']} / {langData['tickets']}</p>
                </div>
            </div>
            <div className='ticket-container'>
                <div className='container-ticket'>
                    <h1 className='ticket-title'>Ж/Д Поезда <img src='' alt='' /></h1>
                    <div className='ticket-cards'>
                        {ticketData.map((ticket, index) => (
                            <div className='ticket-card' key={index}>
                                <h1 className='from'>
                                    <BiSolidArrowFromBottom /> {ticket.from}
                                </h1>
                                <h1 className='to'>
                                    <BiSolidArrowFromTop /> {ticket.to}
                                </h1>
                                {ticket.dates.map((date, idx) => (
                                    <p className='available-date' key={idx}>
                                        <BsCalendar2DayFill /> {date}
                                    </p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='container-ticket'>
                    <h1 className='ticket-title'>Авиаперелет <img src='' alt='' /></h1>
                    <div className='ticket-cardss'>
                        {ticketPlane.map((ticket, index) => (
                            <div className='ticket-card' key={index}>
                                <h1 className='from'>
                                    <BiSolidArrowFromBottom /> {ticket.from}
                                </h1>
                                <h1 className='to'>
                                    <BiSolidArrowFromTop /> {ticket.to}
                                </h1>
                                {ticket.dates.map((date, idx) => (
                                    <p className='available-date' key={idx}>
                                        <BsCalendar2DayFill /> {date}
                                    </p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Tickets;
