import React, { useMemo } from 'react';
import { Link } from "react-router-dom";

import Place6 from '../images/Place6.png'
import Place5 from '../images/Place5.png'
import Place7 from '../images/Place7.png'
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json"


import './Card.css';

const Card2 = () => {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);

  return (
    <div className="home-page">
      <h1 className='home-1'>{langData['national-tours']}</h1>
      <div className="image-grid">
        <div className="image-item">
      <div className="overlay"></div>
          <img src={Place6} alt="" />
          <Link to="/kirgizistan" className='image-text'>{langData['kirgiziya']}</Link>
        </div>
        <div className="image-item">
        <div className="overlay"></div>
          <img src={Place5} alt="" />
          <Link to="/tadjikistan" className='image-text'>{langData['tadjikistan']}</Link>
        </div>
        <div className="image-item">
        <div className="overlay"></div>
          <img src={Place7} alt="" />
          <Link to="/kazakhstan" className='image-text'>{langData['kazakhstan']}</Link>
        </div>
      </div>
    </div>
  );
};

export default Card2;
