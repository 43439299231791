import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../Pages/Tours.css'; 
import Footer from '../Footer';
import { BASE_IMG, getAllToursTad, formatDateTime } from '../../api';
import { useLang } from '../../redux/selectors';


const TourTadjik = () => {
  const lang = useLang();
  const [tourTad, setTourTad] = useState([]);

  useEffect(() => {
    getAllToursTad().then(({ data }) => {
      setTourTad(data);
    }).catch((error) => {
      console.error(error);
    })
  }, [])


  return (
    <main>
      <div className="top-back">
        <div className="content">
          <h2>Таджикистан</h2>
          <p>Главный / Туры / Таджикистан</p>
        </div>
      </div>
      <div className="tours-container">
        <h1 className="tours-header">Наши туры</h1>
        <div className="card-tours">
          {tourTad.map(tour => (<Link to={`/tours/${tour.id}`} className="details-link">
            <div className="tours-card" key={tour.id}>
              <img src={BASE_IMG +tour.img} alt={tour.title} />
              <div className="card-content">
                <div className="card-title">{tour[`title_${lang}`]}</div>
                <div className="card-description">{formatDateTime(tour.created_at)}</div><br />
              </div>
            </div>
          </Link>
          ))}
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default TourTadjik;